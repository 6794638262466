import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "USA",
        description: "Since partnering with Winzee for our staffing needs, we've seen a significant improvement in our team's performance and overall synergy. Their expertise in identifying the right talent has proven invaluable, consistently providing us with exceptional candidates that fit our company culture and drive success.",
        name: "John Due",
        subtitle: "CEO, Test Inc",
        image: "testimonial-dark-01"
    },
    {
        form: "USA",
        description: "Our collaboration with winzee has revolutionized our IT infrastructure, paving the way for enhanced efficiency, security, and innovation. Their tailored solutions and exceptional support have not only resolved our pressing IT challenges, but have also positioned us for long-term success in an increasingly competitive digital landscape.",
        name: "Mary Li",
        subtitle: "CTO, Clientale Inc",
        image: "testimonial-dark-02"
    },
    {
        form: "USA",
        description: "Winzee has transformed our marketing approach, delivering targeted, data-driven campaigns that have significantly elevated our brand visibility and customer engagement. Their strategic insights and creative expertise have been instrumental in driving measurable growth and expanding our market presence.",
        name: "Janen",
        subtitle: "Marketing Head, Twitt Corp",
        image: "testimonial-dark-03"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















